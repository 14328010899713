import {
	DestroyRef,
	ElementRef,
	inject,
	Injectable,
	Injector,
	NgZone,
	runInInjectionContext,
} from '@angular/core';
import { Subject } from 'rxjs';
import { assertInjector } from 'ngxtension/assert-injector';
import { injectDestroy } from 'ngxtension/inject-destroy';

@Injectable({ providedIn: 'root' })
export class IsInViewportService {
	private ngZone = inject(NgZone);

	#observerListeners = new Map<Element, Subject<IntersectionObserverEntry>>();

	#observer?: IntersectionObserver;

	#createObserver() {
		this.#observer = this.ngZone.runOutsideAngular(() => {
			return new IntersectionObserver(entries => {
				for (const entry of entries) {
					this.#observerListeners.get(entry.target)?.next(entry);
				}
			});
		});
	}

	observe(element: Element) {
		if (!this.#observer) {
			this.#createObserver();
		}

		if (this.#observerListeners.has(element)) {
			return this.#observerListeners.get(element)!;
		}

		this.#observerListeners.set(element, new Subject<IntersectionObserverEntry>());
		this.#observer?.observe(element);

		return this.#observerListeners.get(element)!;
	}

	unobserve(element: Element) {
		this.#observer?.unobserve(element);

		this.#observerListeners.get(element)?.complete();
		this.#observerListeners.delete(element);

		if (this.#observerListeners.size === 0) {
			this.#disconnect();
		}
	}

	#disconnect() {
		this.#observer?.disconnect();
		this.#observer = undefined;
	}
}

export interface InjectIsIntersectingOptions {
	injector?: Injector;
	element?: Element;
}

/**
 * Injects an observable that emits whenever the element is intersecting the viewport.
 * The observable will complete when the element is destroyed.
 * @param options
 */
export const injectIsIntersecting = (options?: InjectIsIntersectingOptions) => {
	const injector = assertInjector(injectDestroy, options?.injector);

	return runInInjectionContext(injector, () => {
		const el = options?.element ?? inject(ElementRef).nativeElement;
		const inInViewportService = inject(IsInViewportService);
		const destroyRef = inject(DestroyRef);

		const sub = inInViewportService.observe(el);

		destroyRef.onDestroy(() => {
			inInViewportService.unobserve(el);
		});

		return sub;
	});
};
