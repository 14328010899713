import { inject, Signal } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';
import {
	BOOTSTRAP_BREAKPOINT_MAP,
	BREAKPOINT_MAP,
	BreakpointType,
	SizeScreenType,
} from '@nats/models';
import { toSignal } from '@angular/core/rxjs-interop';

/**
 * Injects the current screen size as an Observable
 * @returns Observable<SizeScreenType>
 */
export const injectCurrentScreenSize = () => {
	const breakpointObserver = inject(BreakpointObserver);

	return breakpointObserver.observe(Object.values(BREAKPOINT_MAP)).pipe(
		map(result => {
			if (result.breakpoints[BREAKPOINT_MAP.phone]) return SizeScreenType.Phone;
			if (result.breakpoints[BREAKPOINT_MAP.desktop]) return SizeScreenType.Desktop;
			return SizeScreenType.Desktop;
		})
	);
};

/**
 * Injects the current screen size as a signal
 * @returns Signal<SizeScreenType>
 */
export const injectScreenSize = () => {
	const screenSize$ = injectCurrentScreenSize();
	return toSignal(screenSize$, { requireSync: true });
};

/**
 * Injects the current bootstrap breakpoint as a Signal
 * @returns Signal<string>
 * @default
 *   xs: '(min-width: 0) and (max-width: 576px)',
 *   sm: '(min-width: 576px) and (max-width: 768px)',
 *   md: '(min-width: 768px) and (max-width: 992px)',
 *   lg: '(min-width: 992px) and (max-width: 1200px)',
 *   xl: '(min-width: 1200px) and (max-width: 1400px)',
 *   xxl: '(min-width: 1400px)',
 */
export const injectCurrentBSBreakpoint = (): Signal<BreakpointType> => {
	const breakpointObserver = inject(BreakpointObserver);

	const bsBreakpoint$: Observable<BreakpointType> = breakpointObserver
		.observe(Object.values(BOOTSTRAP_BREAKPOINT_MAP))
		.pipe(
			map(result => {
				for (const key in BOOTSTRAP_BREAKPOINT_MAP) {
					// @ts-ignore
					const breakpoint = BOOTSTRAP_BREAKPOINT_MAP[key];
					if (result.breakpoints[breakpoint]) {
						return key as BreakpointType;
					}
				}
				return 'lg';
			})
		);

	return toSignal(bsBreakpoint$, { requireSync: true });
};
